export default {

	// api请求地址
	// baseUrl: '{{$baseUrl}}',https://663120.cn/
	// baseUrl: '/api',
	baseUrl: 'https://bgshop.ycgj.site',
	// baseUrl: 'https://663120.cn',

	// 图片域名
	// imgDomain: '{{$imgDomain}}',
	imgDomain: 'https://bgshop.ycgj.site',
	// imgDomain: 'https://hz.liub.cn',

	// 腾讯地图key
	// mpKey: '{{$mpKey}}',
	mpKey: '6ZDBZ-CLSLX-66747-7MVM4-HLK47-XMBXU',

	// 客服
	// webSocket: '{{$webSocket}}',
	webSocket: 'wss://bgshop.ycgj.site/wss',
	// webSocket: 'wss://663120.cn/wss',

	// api安全
	// apiSecurity: "{{$apiSecurity}}",
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	// publicKey: `{{$publicKey}}`,
	publicKey: `-----BEGIN PUBLIC KEY-----
	MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA2/p3B6QkZgb+WcWtKEJA
	LX7C4vl2MbztmRnmMSuOSe4hTX8ixrCNFyrDUE1cFjuidYhEQDiQ6LkUTrbZjlsX
	2zlWxwqjA++6Sx7hDU2QtbRiIQWqUekK8AN/amj1j6kVg1uy+Uvdc51Q783ml8B1
	fOYiJEPIRpS9DAWZZ9Y6hkcqzQPVOOyT3YgCu2N0sDswBzUvNYgClOjqLn1IEZ0c
	xlWc4jweLf2itHHVDOxiwShto7AjnxO6BJ1aGMSvm5tV3D7XrhjR0nDU1cnEdyV+
	8eDzpuOSwce225NtQ7wsuoSkAAJxnx1WVF8mDDJeMOF+SowtmFAiDswga6whFmUi
	1WtJ/svBDj3nTTOPASTnXriPu2qEwGBV39FRX50rFxgf7z/TX3ttuWqOPZkVJyK4
	tlB7WXKht9RG9LXXEc3CgWjj80+VC4RRTL99/4DGf1URsfEA4n6WQyN/pUkrX8dj
	JACJb5JcUmqbe9seDgLpPO/aN4xz5GHzlP4xpThv73I/WK3u2Od7YpIwu0g6fyO+
	BLaIVRypxQWLV5cdWKdrd/SFbn5pToAO/Wl7N1uSrXrMl46nhpFKBDKwmW0a8071
	K69MO1IuvRkLzE6WeBYW/QbbHZiyYitqvMT8T8sBoKZF8VEUWPRm5PaH3X3zJK8k
	D5EN393Bcld+bqpXI/20cqUCAwEAAQ==
	-----END PUBLIC KEY-----`
}