import Vue from "vue"
import router from "./router"
import store from "./store"
import VueMeta from "vue-meta"
import Element from "element-ui"


import {
	Message
} from 'element-ui'

import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
// 全局注册图标
Vue.component('icon', Icon);


// 适配flex
import '@/utils/flexible.js';

// 引入全局css
import './assets/scss/style.scss';

// Vue.use(ElementUI);

//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import 'echarts/map/js/china.js'

let messageInstance = null;
const overrideMessage = (options) => {
		if (messageInstance) {
			messageInstance.close()
		}
		messageInstance = Message(options)
	}
	['error', 'success', 'info', 'warning'].forEach(type => {
		overrideMessage[type] = options => {
			if (typeof options === 'string') {
				options = {
					message: options
				}
			}
			options.type = type
			return overrideMessage(options)
		}
	})
Vue.use(Element)
Vue.prototype.$message = overrideMessage;

import scroll from 'vue-seamless-scroll'

import "element-ui/lib/theme-chalk/index.css"
import "./assets/styles/main.scss"
import "./assets/styles/element-variables.scss"


import VideoPlayer from "vue-video-player"
import "video.js/dist/video-js.css"

import App from "./App.vue"

import Util from "./utils/util"
import Lang from "./utils/lang.js"
import Config from "./utils/config.js"

Vue.prototype.$store = store //挂在vue
Vue.config.productionTip = false

// 常用工具函数
Vue.prototype.$util = Util
Vue.prototype.$img = Util.img
Vue.prototype.$timeStampTurnTime = Util.timeStampTurnTime
Vue.prototype.$copy = Util.copy
window.util = Util
window.img = Util.img
window.timeStampTurnTime = Util.timeStampTurnTime
window.copy = Util.copy

// 语言包
Vue.prototype.$langConfig = Lang //语言包对象
Vue.prototype.$lang = Lang.lang //解析语言包
window.langConfig = Lang
window.lang = Lang.lang

Vue.prototype.$config = Config

Vue.use(VideoPlayer)
Vue.use(VueMeta)
Vue.use(scroll)

window.vue = new Vue({
	router,
	store,
	metaInfo() {
		return {
			title: store.getters.siteInfo.title || "",
			meta: [{
					name: "title",
					content: store.getters.siteInfo.title || ""
				},
				{
					name: "desc",
					content: store.getters.siteInfo.desc || ""
				},
				{
					name: "keywords",
					content: store.getters.siteInfo.keywords || ""
				}
			]
		}
	},
	render: h => h(App)
}).$mount("#app")